import React, { useState, useRef } from "react";
import {
  Paper,
  Grid,
  Typography,
  Tooltip,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const CancelReasonsPopup = ({ open, onClose, onNext }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const descriptionElementRef = useRef(null);
  const scroll = "paper"; // Ensures scrolling for long content

  const reasons = [
    "I did not receive an item(s)",
    "I received a Damaged item(s)",
    "I have received a Wrong product(s)",
    "I am not satisfied with a product(s) quality",
    "I am not satisfied with the product's shelf life / validity",
    "I have not received my order.",
  ];

  const handleNext = () => {
    if (selectedReason) {
      onNext(selectedReason);
    } else {
      alert("Please select a reason before proceeding.");
    }
  };

  return (
    <Dialog
      open={open}
      scroll={scroll}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h5"
          color="black"
          style={{ margin: "0.5rem", textTransform: "none" }}
        >
          {"Select a reason for cancellation"}
        </Typography>
      </DialogTitle>

      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText
          id="alert-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {reasons.map((reason, index) => (
            <div
              key={index}
              className={`p-2 border rounded-md cursor-pointer my-1 ${
                selectedReason === reason
                  ? "bg-red-100 border-red-500"
                  : "bg-gray-100"
              }`}
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                cursor: "pointer",
                textTransform: "none",
                backgroundColor:
                  selectedReason === reason ? "#ffe6e6" : "#f9f9f9",
                marginBottom: "5px",
              }}
              onClick={() => setSelectedReason(reason)}
            >
              {reason}
            </div>
          ))}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Tooltip title="Cancel" arrow>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            fullWidth={true}
            style={{
              textTransform: "capitalize",
              width: "20vh",
              borderRadius: "5px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Tooltip>

        <Tooltip title="Next" arrow>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            fullWidth={true}
            style={{
              textTransform: "capitalize",
              width: "20vh",
              borderRadius: "5px",
            }}
            onClick={handleNext}
          >
            Next
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CancelReasonsPopup;
