export const DOMESTIC_LIST_REQUEST = "DOMESTIC_LIST_REQUEST";
export const DOMESTIC_LIST_SUCCESS = "DOMESTIC_LIST_SUCCESS";
export const DOMESTIC_LIST_FAIL = "DOMESTIC_LIST_FAIL";

export const DOMESTIC_LIST_BY_PRODUCT_ID_REQUEST =
  "DOMESTIC_LIST_BY_PRODUCT_ID_REQUEST";
export const DOMESTIC_LIST_BY_PRODUCT_ID_SUCCESS =
  "DOMESTIC_LIST_BY_PRODUCT_ID_SUCCESS";
export const DOMESTIC_LIST_BY_PRODUCT_ID_FAIL =
  "DOMESTIC_LIST_BY_PRODUCT_ID_FAIL";

export const DOMESTIC_DELETE_BY_PRODUCT_ID_REQUEST =
  "DOMESTIC_DELETE_BY_PRODUCT_ID_REQUEST";
export const DOMESTIC_DELETE_BY_PRODUCT_ID_SUCCESS =
  "DOMESTIC_DELETE_BY_PRODUCT_ID_SUCCESS";
export const DOMESTIC_DELETE_BY_PRODUCT_ID_FAIL =
  "DOMESTIC_DELETE_BY_PRODUCT_ID_FAIL";

export const DOMESTIC_CREATE_BY_PRODUCT_ID_REQUEST =
  "DOMESTIC_CREATE_BY_PRODUCT_ID_REQUEST";
export const DOMESTIC_CREATE_BY_PRODUCT_ID_SUCCESS =
  "DOMESTIC_CREATE_BY_PRODUCT_ID_SUCCESS";
export const DOMESTIC_CREATE_BY_PRODUCT_ID_FAIL =
  "DOMESTIC_CREATE_BY_PRODUCT_ID_FAIL";

export const DOMESTIC_UPDATE_BY_PRODUCT_ID_REQUEST =
  "DOMESTIC_UPDATE_BY_PRODUCT_ID_REQUEST";
export const DOMESTIC_UPDATE_BY_PRODUCT_ID_SUCCESS =
  "DOMESTIC_UPDATE_BY_PRODUCT_ID_SUCCESS";
export const DOMESTIC_UPDATE_BY_PRODUCT_ID_FAIL =
  "DOMESTIC_UPDATE_BY_PRODUCT_ID_FAIL";

  export const DOMESTIC_BEST_SELLERS_PRICE_REQUEST =
  "DOMESTIC_BEST_SELLERS_PRICE_REQUEST";
export const DOMESTIC_BEST_SELLERS_PRICE_SUCCESS =
  "DOMESTIC_BEST_SELLERS_PRICE_SUCCESS";
export const DOMESTIC_BEST_SELLERS_PRICE_FAIL =
  "DOMESTIC_BEST_SELLERS_PRICE_FAIL";
  export const DOMESTIC_BEST_SELLERS_PRICE_RESET =
  "DOMESTIC_BEST_SELLERS_PRICE_RESET";

  export const PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_REQUEST =
  "PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_REQUEST";
export const PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_SUCCESS =
  "PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_SUCCESS";
export const PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_FAIL =
  "PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_FAIL";
  export const PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_RESET =
  "PRODUCTS_DOMESTIC_PRICE_BY_SUBCATEGORY_RESET";
