import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Section } from "./organisms";
import { team, gallery } from "../data";
import { Divider, Grid, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import { privacy } from "../data/privacyPolicy.js";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: "0 5px 20px 0 rgba(90, 202, 157, 0.05)",
    "& .section-alternate__content": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <div style={{ marginTop: "1rem" }}>
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: "0.1rem" }}>
        <Section style={{ marginTop: "0.1rem", padding: "1rem" }}>

          <h2 style={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: "1.5rem",
            fontWeight: "700",
            color: '#000',
            textAlign: "left",
            // margin: '1em'
          }}>Privacy Policy</h2>

          <Divider style={{ margin: "0.5rem" }} />
          <h3 style={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: "1rem",
            fontWeight: "600",
            color: '#000',
            textAlign: "left",
            textTransform: "none",
            // margin: '1em',
            fontSize: "0.75rem"
          }}>{privacy.privacyHeaderContent}</h3>

          {privacy.interpretationsContent.map((t, index) => (
            <div>
              <h2 style={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: '#000',
                textAlign: "left",
                // margin: '1em'
              }}>{t.title ? t.title : null}</h2>
              <div style={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: "1rem",
                fontWeight: "600",
                color: 'black',
                textAlign: "left",
                // margin: '1em'
              }}>{t.content ? t.content.map(tcontent => (
                <div>
                  <h2 style={{
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "1.25rem",
                    fontWeight: "700",
                    color: '#000',
                    textAlign: "left",
                    // margin: '1em'
                  }}>{tcontent.subtitle ? tcontent.subtitle : null}</h2>
                  {tcontent.content ? <h3 style={{
                    textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "0.75rem", fontWeight: "600"
                  }}>{tcontent.content}</h3> : null}
                  {tcontent.actionPoints ? tcontent.actionPoints.map(tcnt => (<>
                    <div>
                      {/* {tcnt.title ? <h4 style={{ textTransform: "none" }}>{tcnt.title}</h4> : null}  */}
                      <h2 style={{
                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: '#000',
                        textAlign: "left",
                        // margin: '1em'
                      }}>{tcnt.title ? tcnt.title : null}</h2>
                    </div>
                    <div>{tcnt.description ? <h3 style={{
                      textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "0.75rem", fontWeight: "600"
                    }}>{tcnt.description}</h3> : null} </div>
                  </>)
                  ) : null}
                </div>
              )) : null}
              </div>
            </div>
          )
          )}
          {/* Collecting Data Content */}

          {privacy.collectingDataContent.map((t, index) => (
            <div>
              <h2 style={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: '#000',
                textAlign: "left",
                // margin: '1em'
              }}>{t.title ? t.title : null}</h2>
              <h2 style={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: '#000',
                textAlign: "left",
                // margin: '1em'
              }}>{t.subTitle ? t.subTitle : null}</h2>
              <div style={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: "0.95rem",
                fontWeight: "300",
                color: 'black',
                textAlign: "left",
                // margin: '1em'
              }}>{t.content ? t.content.map(tcontent => (
                <div>
                  {/* <h3>{tcontent.subtitle}</h3> */}
                  <h2 style={{
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "1.25rem",
                    fontWeight: "700",
                    color: '#000',
                    textAlign: "left",
                    // margin: '1em'
                  }}>{tcontent.subtitle ? tcontent.subtitle : null}</h2>

                  {tcontent.content ? <h3 style={{
                    textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "0.75rem", fontWeight: "600"
                  }}>{tcontent.content}</h3> : null}
                  {tcontent.actionPoints ? tcontent.actionPoints.map(tcnt => (<>
                    <div>
                      {/* {tcnt.title ? <h4 style={{ textTransform: "none" }}>{tcnt.title}</h4> : null}  */}
                      <h3 style={{
                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                        fontSize: "1.25rem",
                        fontWeight: "700",
                        color: '#000',
                        textAlign: "left",
                        // margin: '1em'
                      }}>{tcnt.title ? tcnt.title : null}</h3>
                    </div>
                    <div>{tcnt.description ? <h3 style={{
                      textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "0.75rem", fontWeight: "600"
                    }}>{tcnt.description}</h3> : null} </div>
                  </>)
                  ) : null}
                  {tcontent.subContent1 ? <h3 style={{
                    textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "0.75rem", fontWeight: "600"
                  }}>{tcontent.subContent1}</h3> : null}
                  {tcontent.subContent2 ? <h3 style={{
                    textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "0.75rem", fontWeight: "600"
                  }}>{tcontent.subContent2}</h3> : null}
                  {tcontent.subContent2ActionPoints ? tcontent.subContent2ActionPoints.map(scap => (<>
                    <div>
                      {/* {scap.title ? <h4 style={{ textTransform: "none" }}>{scap.title}</h4> : null} */}
                      <h4 style={{
                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                        fontSize: "1.25rem",
                        fontWeight: "700",
                        color: '#000',
                        textAlign: "left",
                        // margin: '1em'
                      }}>{scap.title ? scap.title : null}</h4>
                    </div>
                    <div><h3 style={{
                      textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "0.75rem", fontWeight: "600"
                    }}> {scap.type ? scap.type : null}  {scap.typeDescription ? scap.typeDescription : scap.description ? scap.description : null} </h3> </div>
                    <div><h3 style={{
                      textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "0.75rem", fontWeight: "600"
                    }}> {scap.administeredBy ? scap.administeredBy : null}  {scap.administeredByValue ? scap.administeredByValue : null} </h3> </div>
                    <div><h3 style={{
                      textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "0.75rem", fontWeight: "600"
                    }}> {scap.purpose ? scap.purpose : null}  {scap.purposeValue ? scap.purposeValue : null} </h3> </div>
                  </>)
                  ) : null}
                  {tcontent.subContentFooter ? <h3 style={{
                    textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    fontSize: "0.75rem", fontWeight: "600"
                  }}>{tcontent.subContentFooter}</h3> : null}
                </div>
              )) : null}
              </div>
            </div>
          )
          )}
        </Section>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;
