export const CALCULATE_NEAREST_STORE_REQUEST =
  "CALCULATE_NEAREST_STORE_REQUEST";
export const CALCULATE_NEAREST_STORE_SUCCESS =
  "CALCULATE_NEAREST_STORE_SUCCESS";
export const CALCULATE_NEAREST_STORE_FAIL = "CALCULATE_NEAREST_STORE_FAIL";
export const CALCULATE_NEAREST_STORE_RESET = "CALCULATE_NEAREST_STORE_RESET";

export const CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST =
  "CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST";
export const CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS =
  "CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS";
export const CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL =
  "CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL";
export const CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET =
  "CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET";

export const CHECK_SERVICE_AVAILABILITY_REQUEST =
  "CHECK_SERVICE_AVAILABILITY_REQUEST";
export const CHECK_SERVICE_AVAILABILITY_SUCCESS =
  "CHECK_SERVICE_AVAILABILITY_SUCCESS";
export const CHECK_SERVICE_AVAILABILITY_FAIL =
  "CHECK_SERVICE_AVAILABILITY_FAIL";
export const CHECK_SERVICE_AVAILABILITY_RESET =
  "CHECK_SERVICE_AVAILABILITY_RESET";

  export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_REQUEST =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_REQUEST";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_SUCCESS =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_SUCCESS";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_FAIL =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_FAIL";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_RESET =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_RESET";

  export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_REQUEST =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_REQUEST";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_SUCCESS =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_SUCCESS";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_FAIL =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_FAIL";
export const CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_RESET =
  "CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_RESET";
