import React, { useState } from 'react';
import { auth } from '../firebase/setup'; // Ensure the correct path
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import {
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import { Divider } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const OtpAuthentication = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [user, setUser] = useState(null);

  // Function to Send OTP
  const sendOTP = async () => {
    console.log("Clicked sendOtp.!")
    try {
      const recaptcha = new RecaptchaVerifier(auth,"recaptcha", {})
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptcha);
      console.log('Sent OTP Successfully...!')
      setUser('confirmation :  ', confirmation)
      console.log("OTP sent successfully : confirmation :==>> ", confirmation);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };       

  // Function to verify OTP
  const verifyOTP = async () => {
    try {
      await user.confirm(otp);
      alert("Login successful!");
    } catch (error) {
      console.error("Invalid OTP:", error);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" justify="center" >
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: "5rem" }}>
          <CardHeader>
            <h2 align="center"> Tagline Store </h2>
            <Divider />
            <h2 align="center"> OTP Authentication </h2>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2} justifyContent="center" marginTop="5rem">
              {/* <Grid
                item
                xs={12}
                align="center"
                justifyContent="center"
                margin="auto"
              >
                <PhoneInput
                  country={'us'}
                  value={phoneNumber}
                  style={{ marginBottom: "1rem", width: "35vh", color: "black" }}
                  onChange={phone => setPhoneNumber(() => phone)}
                />

              </Grid> */}
              <Grid
                item
                xs={12}
                align="center"
                justifyContent="center"
                margin="auto"
              >
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  name="phoneNumber"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="text"
                  style={{ marginBottom: "1rem", width: "35vh", color: "black" }}
                  value={phoneNumber || ""}
                />
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                justifyContent="center"
                margin="auto"
              >
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={sendOTP}
                  style={{
                    textTransform: "capitalize",
                    width: "20vh",
                    borderRadius: "5px",
                  }}
                >
                  Send OTP
                </Button>
              </Grid>
              {otpSent && (
                <Grid
                  item
                  xs={12}
                  align="center"
                  justifyContent="center"
                  margin="auto"
                >
                  <TextField
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    sx={{marginTop:"1rem"}}
                  />
                  <button onClick={verifyOTP}>Verify OTP</button>
                </Grid>
              )}
              <div id="recaptcha" style={{margin:"1rem"}}></div>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    </Grid >
  )
}
export default OtpAuthentication;
