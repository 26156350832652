import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Button,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ReturnItemsPopup = ({ open, onClose, onConfirm, orderItems }) => {
  const [selectedItems, setSelectedItems] = useState(
    orderItems.map((item) => ({ ...item, selected: false, returnQuantity: 1 }))
  );

  const handleCheckboxChange = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems[index].selected = !updatedItems[index].selected;
    setSelectedItems(updatedItems);
  };

  const handleQuantityChange = (index, change) => {
    const updatedItems = [...selectedItems];
    console.log("updatedItems : ", updatedItems);
    if (
      updatedItems[index].returnQuantity + change >= 1 &&
      updatedItems[index].returnQuantity + change <=
        updatedItems[index].totalQuantity
    ) {
      updatedItems[index].returnQuantity += change;
    }
    setSelectedItems(updatedItems);
  };

  const handleConfirm = () => {
    const itemsToReturn = selectedItems.filter((item) => item.selected);
    if (itemsToReturn.length === 0) {
      alert("Please select at least one item to return.");
      return;
    }
    onConfirm(itemsToReturn);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="return-dialog-title">
      <DialogTitle id="return-dialog-title">
        <Typography
          variant="h5"
          color="black"
          style={{ margin: "0.5rem", textTransform: "none" }}
        >
          Select Items & quantities to proceed
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        {selectedItems.map((item, index) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "10px 0",
            }}
          >
            <Checkbox
              checked={item.selected}
              onChange={() => handleCheckboxChange(index)}
            />
            <img
              src={item.image}
              alt={item.name}
              style={{ width: "50px", height: "50px", marginRight: "10px" }}
            />
            <div style={{ flex: 1 }}>
              <Typography variant="body1">{item.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total quantity billed: {item.totalQuantity}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => handleQuantityChange(index, -1)}
                disabled={!item.selected}
              >
                <RemoveIcon />
              </IconButton>

              <Typography>{item.returnQuantity}</Typography>
              <IconButton
                onClick={() => handleQuantityChange(index, 1)}
                disabled={!item.selected}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <Tooltip title="Cancel" arrow>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            fullWidth={true}
            style={{
              textTransform: "capitalize",
              width: "20vh",
              borderRadius: "5px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Tooltip>
        <Tooltip title="Save" arrow>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            fullWidth={true}
            style={{
              textTransform: "capitalize",
              width: "20vh",
              borderRadius: "5px",
            }}
            onClick={handleConfirm}
            disabled={!selectedItems.some((item) => item.selected)}
          >
            Save
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ReturnItemsPopup;
