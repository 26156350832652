// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// SUNILS APPSDEVPRENEUR247@gmail.com : firebase config
/**
const firebaseConfig = {
  apiKey: "AIzaSyC7BgLYSPqbbTVU9qnTZ1nsTKUjWczR6uU",
  authDomain: "tagline-traders-firebase.firebaseapp.com",
  projectId: "tagline-traders-firebase",
  storageBucket: "tagline-traders-firebase.firebasestorage.app",
  messagingSenderId: "242635946726",
  appId: "1:242635946726:web:63b071de37791231691927",
  measurementId: "G-BB7N59EB0E"
};
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeDokN2Y0HV6cdeEomULxStX7kQ8pkZkU",
  authDomain: "tagline-traders-firebase-d8af7.firebaseapp.com",
  projectId: "tagline-traders-firebase-d8af7",
  storageBucket: "tagline-traders-firebase-d8af7.firebasestorage.app",
  messagingSenderId: "525950743614",
  appId: "1:525950743614:web:89f1771bc554fc30a50d6f",
  measurementId: "G-0MBV6ZPB4D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore()
const analytics = getAnalytics(app);