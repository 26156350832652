// import CategoriesSubMenu from "./components/CategoriesSubMenu";
import React , { Suspense, lazy, useEffect ,useState }from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, ThemeProvider } from "@mui/material";
import theme from "./components/Theme";
import CustomBackdropSpinner from "./components/CustomBackdropSpinner";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import OtpAuthentication from "./components/OtpAuthentication";
import "./custom.styles.css";
import MyOrders from "./components/MyOrders";

const Dashboard  = React.lazy(()=> import( "./components/Dashboard"));
const CustomHeader  = React.lazy(()=> import( "./components/CustomHeader"));
const Signin  = React.lazy(()=> import( "./components/Signin"));
const ProductScreen  = React.lazy(()=> import( "./components/ProductScreen"));
const CartScreen  = React.lazy(()=> import( "./components/CartScreen"));
const SubCategoryWiseScreen  = React.lazy(()=> import( "./components/SubCategoryWiseScreen"));
const ShippingScreen  = React.lazy(()=> import( "./components/ShippingScreen"));
const PaymentScreen  = React.lazy(()=> import( "./components/PaymentScreen"));
const OrderReviewScreen  = React.lazy(()=> import( "./components/OrderReviewScreen"));
const ProductsListScreen  = React.lazy(()=> import( "./components/ProductsListScreen"));
const OrderDetailsScreen  = React.lazy(()=> import( "./components/OrderDetailsScreen"));
const OrderConfirmationScreen  = React.lazy(()=> import( "./components/OrderConfirmationScreen"));
const AboutUs  = React.lazy(()=> import( "./components/AboutUs"));
const OrderListScreen  = React.lazy(()=> import( "./components/OrderListScreen"));
const ProfileScreen  = React.lazy(()=> import( "./components/ProfileScreen"));
const OrderTracker  = React.lazy(()=> import( "./components/OrderTracker"));
const Signup  = React.lazy(()=> import( "./components/Signup"));
const ResetPassword  = React.lazy(()=> import( "./components/ResetPassword"));
const Footer  = React.lazy(()=> import( "./components/Footer"));

const App = ()=> {
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(() => {
    const loggedUser = localStorage.getItem('userInfo');
    setLoggedIn(Boolean(loggedUser));
  }, []);

  const handleLoggedIn = () => {
    setLoggedIn(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}>
      { loggedIn !== null && <Router>
        <CustomHeader />
        <main className="py-0" style={{marginTop:"3rem", minHeight: "100vh",
    overflow: "hidden",display: "block",position: "relative",paddingBottom: "50px", background:"#E3E6E6"}}>
          <Container>
            <Routes>
              <Route path="/" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><Dashboard /></React.Suspense>} />
              <Route path="/signin" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><Signin /></React.Suspense>} />
              <Route path="/signup" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><Signup /></React.Suspense>} />
              <Route path="/otpSignin" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OtpAuthentication /></React.Suspense>} />
              <Route path="/admin/users/reset/password" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><ResetPassword /></React.Suspense>} />
              <Route path="/aboutus" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><AboutUs /></React.Suspense>} />
              <Route path="/myorders" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><MyOrders /></React.Suspense>} />
              {/* <Route path="/myorders" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OrderListScreen /></React.Suspense>} /> */}
              <Route path="/subcategory/:categoryId" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><SubCategoryWiseScreen /></React.Suspense>} />
              <Route path="/product/:productId" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><ProductScreen /></React.Suspense>} />
              <Route path="/cart/:productId/:quantitySelected/:uom/:orderTypeSelected/:unitPrice/:taxPrice" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><CartScreen /></React.Suspense>} />
              <Route path="/shipping" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><ShippingScreen /></React.Suspense>} />
              <Route path="/payment" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><PaymentScreen /></React.Suspense>} />
              <Route path="/order-review" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OrderReviewScreen /></React.Suspense>} />
              <Route path="/order-details/:orderId" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OrderDetailsScreen /></React.Suspense>} />
              <Route path="/order/:orderId" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OrderConfirmationScreen /></React.Suspense>} />
              <Route path="/shoppingcart" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><CartScreen /></React.Suspense>} />
              <Route path="/order/track/:id" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><OrderTracker /></React.Suspense>} />
              <Route path="/products/:subCategoryId"
                 element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><ProductsListScreen /></React.Suspense>} />
              <Route path="/terms-conditions" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><TermsConditions /></React.Suspense>} />
              <Route path="/privacy-policy" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><PrivacyPolicy /></React.Suspense>} />
              <Route path="/myprofile" element={<React.Suspense fallback = {<span><CustomBackdropSpinner /></span>}><ProfileScreen /></React.Suspense>} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </Router>}
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
