import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  Grid,
  Card,
  CardContent,
  IconButton,
  Drawer,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderAction";
import { formatToIndianCurrency } from "../util/Util";
import CustomBackdropSpinner from "../components/CustomBackdropSpinner";
import Message from "./Message.js";
import ReturnItemsPopup from "./ReturnItemsPopup.js";
import CancelReasonsPopup from "./CancelReasonsPopup.js";
import SidebarScreen from "./SidebarScreen.js";

const MyOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, orders, error } = useSelector((state) => state.myOrdersList);
  const [showPopup, setShowPopup] = useState(false);
  const [openItemsCancelPopup, setOpenItemsCancelPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (userInfo) {
      dispatch(listMyOrders());
    }
  }, [dispatch, userInfo]);

  const handleNextStep = (reason) => {
    console.log("User selected reason:", reason);
    setOpenItemsCancelPopup(true);
  };
  const handleConfirmReturn = (items) => {
    console.log("User selected items:", items);
    setShowPopup(false);
    setOpenItemsCancelPopup(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        marginTop: "6rem",
      }}
    >
      {/* Sidebar */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSidebarOpen}
        sx={{
          width: isSidebarOpen ? "240px" : "0px",
          flexShrink: 0,
          transition: "width 0.3s ease",
          zIndex: 100, // Keep sidebar below header but above content
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
            position: "fixed",
          },
        }}
      >
        <SidebarScreen />
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          transition: "margin 0.3s ease",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            backgroundColor: "#fff",
            boxShadow: 1,
            width: "90%",
            zIndex: 1201, // Ensure header is always above sidebar
            position: "sticky",
            top: 0,
          }}
        >
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{
              marginLeft: isSidebarOpen ? "240px" : "0px",
              transition: "margin 0.3s ease",
            }}
          >
            My Orders
          </Typography>
        </Box>

        {/* Order List */}
        <Box
          sx={{
            padding: "2rem",
            transition: "margin 0.3s ease",
          }}
        >
          {loading && <CustomBackdropSpinner />}
          {error && (
            <Message variant="danger" severity="error">
              {error}
            </Message>
          )}
          {orders && orders.length > 0 ? (
            orders.map((order) => (
              <Accordion
                key={order._id}
                sx={{
                  marginBottom: "1rem",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  textAlign: "left",
                  marginLeft: "0.1rem",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ backgroundColor: "#f8f9fa" }}
                >
                  <Typography>
                    <strong>Order Date:</strong> {order.billDate} |
                    <strong> Order No:</strong> {order._id} |
                    <strong> Total Amount:</strong>{" "}
                    {formatToIndianCurrency(
                      order.paymentDetails?.totalPrice || 0
                    )}{" "}
                    |<strong> Total Items:</strong>{" "}
                    {order.orderItems?.reduce(
                      (sum, item) => sum + item.quantity,
                      0
                    ) || 0}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography>
                            <strong>Payment Mode:</strong>{" "}
                            {order.paymentMethod || "N/A"}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "bold", marginTop: "1rem" }}
                          >
                            Delivery Address
                          </Typography>
                          <Typography>{order.shippingAddress?.name}</Typography>
                          <Typography>
                            {order.shippingAddress?.address}
                          </Typography>
                          <Typography>
                            {order.shippingAddress?.city},{" "}
                            {order.shippingAddress?.state} -{" "}
                            {order.shippingAddress?.postalCode}
                          </Typography>
                          <Typography>
                            Landmark: {order.shippingAddress?.landmark || "N/A"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                          >
                            Order Summary
                          </Typography>
                          <Typography>
                            <strong>Total Amount:</strong>{" "}
                            {formatToIndianCurrency(
                              order.paymentDetails?.totalPrice || 0
                            )}
                          </Typography>
                          <Typography>
                            <strong>Delivery Charges:</strong>{" "}
                            {order.paymentDetails?.deliveryCharge > 0
                              ? formatToIndianCurrency(
                                  order.paymentDetails?.deliveryCharge
                                )
                              : "Free"}
                          </Typography>
                          <Typography sx={{ fontSize: "12px", color: "#888" }}>
                            (Includes Tax:{" "}
                            {formatToIndianCurrency(
                              order.paymentDetails?.tax || 0
                            )}
                            )
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate(`/order/${order._id}`)}
                        style={{
                          margin: "0.5rem",
                          fontFamily: "Montserrat, Arial, sans-serif",
                          fontSize: "0.75rem",
                          color: "black",
                        }}
                      >
                        View Details
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setShowPopup(true)}
                        style={{
                          margin: "0.5rem",
                          fontFamily: "Montserrat, Arial, sans-serif",
                          fontSize: "0.75rem",
                          color: "black",
                        }}
                      >
                        Need Help
                      </Button>
                    </Grid>
                  </Grid>
                  {showPopup && (
                    <CancelReasonsPopup
                      open={showPopup}
                      onClose={() => setShowPopup(false)}
                      onNext={handleNextStep}
                    />
                  )}
                  <ReturnItemsPopup
                    open={openItemsCancelPopup}
                    onClose={() => setOpenItemsCancelPopup(false)}
                    onConfirm={handleConfirmReturn}
                    orderItems={order.orderItems || []}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography align="center">No orders found.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyOrders;
