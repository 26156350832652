import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import {
  AccountCircle,
  HistoryOutlined as HistoryEduIcon,
} from "@mui/icons-material";

const SidebarScreen = ({ activeItem }) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      text: "My Orders",
      icon: <HistoryEduIcon />,
      path: "/myorders",
      key: "myorders",
    },
    {
      text: "My Profile",
      icon: <AccountCircleIcon />,
      path: "/myprofile",
      key: "myprofile",
    },
  ];

  return (
    <List style={{ marginTop: "5rem" }}>
      {menuItems.map((item) => (
        <ListItem
          button
          key={item.key}
          onClick={() => navigate(item.path)}
          sx={{
            color: "green",
            fontWeight: "bold",
            backgroundColor: activeItem === item.key ? "#e8f5e9" : "inherit",
            "&:hover": { backgroundColor: "#e8f5e99" },
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          <Divider />
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarScreen;
