import axios from "axios";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_REQUEST,
  LIST_MY_ORDERS_REQUEST,
  LIST_MY_ORDERS_SUCCESS,
  LIST_MY_ORDERS_FAIL,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_INVOICE_GENERATE_EMAIL_RESET,
  ORDER_INVOICE_GENERATE_EMAIL_REQUEST,
  ORDER_INVOICE_GENERATE_EMAIL_SUCCESS,
  ORDER_INVOICE_GENERATE_EMAIL_FAIL,
  ORDER_CREATE_RESET,
} from "../constants/orderConstants";
import {
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
} from "../constants/stockConstants";
import { logout } from "./userAction";

export const createOrder = (order) => async (dispatch, getState) => {
  //console.log("Inside craeteOrder ...", order);
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const id = userInfo._id;
    //console.log("userInfo: ", id);
    const { data } = await axios.post(`/api/orders/${id}`, order, config);

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
    // dispatch({
    //   type: ORDER_INVOICE_GENERATE_EMAIL_REQUEST,
    //   payload: data,
    // });

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });

    // dispatch({
    //   type: ORDER_CREATE_RESET,
    //   payload: {},
    // });
    localStorage.removeItem("cartItems");
    // localStorage.removeItem("storeInfoByZipcode");
    // localStorage.removeItem("storeInfo");
    localStorage.removeItem("paymentMethod");
    // localStorage.removeItem("storeInfoByZipcode"); // NEED TO REMOVE storeInfoByZipcode
    // localStorage.removeItem("userInfo"); // NEED TO REMOVE userInfo
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const getOrderDetailsById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/orders/${id}`, config);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const payOrder =
  (orderId, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/orders/${orderId}/pay`,
        paymentResult,
        config
      );

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_PAY_FAIL,
        payload: message,
      });
    }
  };

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/orders/${order._id}/deliver`,
      {},
      config
    );

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload: message,
    });
  }
};

export const listMyOrders = () => async (dispatch, getState) => {
  //console.log("Exec list My Ordeers Action");
  try {
    dispatch({
      type: LIST_MY_ORDERS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/orders/placed/${userInfo._id}`,
      config
    );

    dispatch({
      type: LIST_MY_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: LIST_MY_ORDERS_FAIL,
      payload: message,
    });
  }
};

export const cancelOrder = (id) => async (dispatch, getState) => {
  console.log("Inside cancelOrder ...", id);
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const { data } = await axios.put(`/api/orders/${id}/cancel`);

    dispatch({
      type: ORDER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const generateInvoiceEmail =
  (order, customerName, adminEmail, storeManagerEmail, customerEmail) =>
  async (dispatch, getState) => {
    //console.log("Inside generateInvoiceEmail ACTION...", order);
    /**console.log(
      " Inside generateInvoiceEmail ACTION...adminEmail, storeManagerEmail, customerEmail, customerName",
      customerName,
      adminEmail,
      storeManagerEmail,
      customerEmail
    );*/
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({
        type: ORDER_INVOICE_GENERATE_EMAIL_REQUEST,
      });

      const { data } = await axios.post(
        `/api/orders/invoice/email`,
        { order, customerName, adminEmail, storeManagerEmail, customerEmail },
        config
      );

      dispatch({
        type: ORDER_INVOICE_GENERATE_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_INVOICE_GENERATE_EMAIL_FAIL,
        payload: message,
      });
    }
};
