import {
  CALCULATE_NEAREST_STORE_REQUEST,
  CALCULATE_NEAREST_STORE_SUCCESS,
  CALCULATE_NEAREST_STORE_FAIL,
  CALCULATE_NEAREST_STORE_RESET,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_REQUEST,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_SUCCESS,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_FAIL,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_RESET,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_REQUEST,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_RESET,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_FAIL,
  CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_SUCCESS,
} from "../constants/geoConstants";

export const nearestStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_NEAREST_STORE_REQUEST:
      return { loading_nearest_store_result: true };
    case CALCULATE_NEAREST_STORE_SUCCESS:
      return { loading_nearest_store_result: false, storeInfo: action.payload };
    case CALCULATE_NEAREST_STORE_FAIL:
      return { loading_nearest_store_result: false, error: action.payload };
    case CALCULATE_NEAREST_STORE_RESET:
      return { loading_nearest_store_result: false, storeInfo: {} };
    default:
      return state;
  }
};

export const nearestStoreByZipcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST:
      return { loading_nearest_store_result_by_zipcode: true };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS:
      return {
        loading_nearest_store_result_by_zipcode: false,
        storeInfoByZipcode: action.payload,
      };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL:
      return {
        loading_nearest_store_result_by_zipcode: false,
        error: action.payload,
      };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET:
      return {
        loading_nearest_store_result_by_zipcode: false,
        storeInfoByZipcode: {},
      };
    default:
      return state;
  }
};

export const calculateDistanceByZipcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_REQUEST:
      return { delivery_distance_by_zipcode_loading: true };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_SUCCESS:
      return {
        delivery_distance_by_zipcode_loading: false,
        delivery_distance_by_zipcode_data: action.payload,
      };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_FAIL:
      return {
        delivery_distance_by_zipcode_loading: false,
        delivery_distance_by_zipcode_error: action.payload,
      };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_ZIPCODE_RESET:
      return {};

    default:
      return state;
  }
};

export const calculateDistanceByLatLongReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_REQUEST:
      return { delivery_distance_by_lat_long_loading: true };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_SUCCESS:
      return {
        delivery_distance_by_lat_long_loading: false,
        delivery_distance_by_lat_long_data: action.payload,
        delivery_distance_by_lat_long_success: true,
      };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_FAIL:
      return {
        delivery_distance_by_lat_long_loading: false,
        delivery_distance_by_lat_long_error: action.payload,
      };

    case CALCULATE_DISTANCE_TO_PRIMARY_STORE_BY_LAT_LONG_RESET:
      return {};

    default:
      return state;
  }
};


