const findPincode = (obj) => {
    const regex = /^\d+$/; // Regular expression to match numbers
    const result = [];

    // Helper function to recursively search for numbers
    function searchNumbers(obj) {
        // Check if the input is an object
        if (typeof obj === 'object' && obj !== null) {
            // Iterate through each key-value pair
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    // If the value is a string containing only numeric characters, add it to the result array
                    if (typeof value === 'string' && regex.test(value) && value.length >=5) {
                        result.push(value);
                    }
                    // If the value is an object or an array, recursively call searchNumbers
                    else if (typeof value === 'object') {
                        searchNumbers(value);
                    }
                }
            }
        }
    }

    // Start the recursive search
    searchNumbers(obj);

    return result;
}

const groupAndSum = (purchaseOrderProducts) => {
    let result = [];
    purchaseOrderProducts.reduce(function (res, eachProduct) {
      if (!res[eachProduct.name]) {
        res[eachProduct.name] = { name: eachProduct.name, unit: 0.0 };
        result.push(res[eachProduct.name]);
      }
      res[eachProduct.name].unit += eachProduct.unit* eachProduct.quantity;
      return res;
    }, {});
  
    console.log('groupAndSum',result);
    return result;
  };
  
  const transformToDDMMYYY =(date)=>{
    let transformedDate = ""
    if(date){
      const dateArray = date.split('-');
      transformedDate = dateArray[2]+'/'+dateArray[1]+'/'+dateArray[0]
    }
    console.log('transformedDate : ',transformedDate)
    return transformedDate
  }
  
  const buildColDef =(data)=>{
    
  }
  
  const preferredCustomerDiscount = ()=> {
    let x = 0;
    let values = [];
    for (var i = (x+0.5); i <= 10.0; i += 0.5) 
        values.push(i.toFixed(1));
    return values;
  };
  
  const getMonthNameByInt = (i) => {
    console.log("Exec getMonthNameByInt .", typeof i)
    switch (i) {
      case 0:
        return "January"
      case 1:
        return "February"
      case 2:
        return "March"
      case 3:
        return "April"
      case 4:
        return "May"
      case 5:
        return "June"
      case 6:
        return "July"
      case 7:
        return "August"
      case 8:{
        console.log("Matched September ")
        return "September"
      }
      case 9:
        return "October"
      case 10:
        return "November"
      case 11:
        return "December"
      default:
        break;
    }
  }
  
  const getStoreName = (id, stores)=>{
    const storeObject  = stores.filter(each=> each._id === id)
    // )
    console.log("storeObject : ", storeObject[0]);
  }
  
  const getStorOrWarehouseNameById = (stOrWhId)=> {
    console.log("getStorOrWarehouseNameById for store : ",stOrWhId)
    let storeOrWarehouseObj ;
    let storeOrWarehouseName = null;
    if(store && stores && store.length > 0 && stores.length > 0){
      storeOrWarehouseObj = stores.filter(each => each._id === stOrWhId)
    }else if (warehouse && warehouse.length > 0 && warehouseInfos && warehouseInfos.length > 0){
      storeOrWarehouseObj =  warehouseInfos.filter(each => each._id === stOrWhId)
    }
    // storeOrWarehouseObj = store && stores && store.length > 0 && stores.length > 0 ? stores.filter(each => each._id === stOrWhId):(warehouse && warehouse.length > 0 && warehouseInfos && warehouseInfos.length > 0 ? warehouseInfos.filter(each => each._id === stOrWhId):"":"");
    if(storeOrWarehouseObj && storeOrWarehouseObj.length>0){
      console.log("******* storeOrWarehouseObj ********* : ",storeOrWarehouseObj);
      storeOrWarehouseName = storeOrWarehouseObj && storeOrWarehouseObj[0].storeName ? storeOrWarehouseObj[0].storeName :(storeOrWarehouseObj[0].warehouseName?storeOrWarehouseObj[0].warehouseName : "");
      if(storeOrWarehouseName && storeOrWarehouseName.length > 0 ){
        setAutoCompleteFromWhStoreValue(prev => storeOrWarehouseName);
        console.log("Exe getStorOrWarehouseNameById for  Id : ",stOrWhId," Store Or Warehouse Name : " ,storeOrWarehouseName);
      }
    }
  }
  
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };


  const formatToIndianCurrency = (amount)=> {
    // Convert the number to a string
    let numStr = amount.toString();
  
    // Split the integer and decimal parts
    let [integerPart, decimalPart] = numStr.split('.');
  
    // Use regex to format the integer part in Indian numbering
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    
    // Group other digits in pairs of two
    const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits.length > 0 ? ',' : '') + lastThreeDigits;
  
    // Format the decimal part if it exists
    const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '.00';
  
    // Combine and return the final formatted currency
    return formattedIntegerPart + formattedDecimalPart;
  }

  const cleanStringsWithoutDimensions = (inputArray) =>  {
    return inputArray.map(item => {
      // Remove numbers and specified units/strings, including concatenated ones
      return item
        .replace(/\b(\d+(GM|GRAMS?|KG|LTR|TIN|POUCH|PIECE)?)\b/gi, '') // Remove numbers with units
        .replace(/\b(GM|GRAMS?|KG|LTR|TIN|POUCH|PIECE)\b/gi, '') // Remove standalone units
        .trim() // Remove leading/trailing spaces
        .replace(/\s+/g, ' '); // Normalize multiple spaces to a single space
    });
  }
  const cleanProductNameWithoutDimensions = (input) =>  {
      // Remove numbers and specified units/strings, including concatenated ones
      return input
        .replace(/\b(\d+(GM|GRAMS?|KG|LTR|TIN|POUCH|PIECE|ML)?)\b/gi, '') // Remove numbers with units
        .replace(/\b(GM|GRAMS?|KG|LTR|TIN|POUCH|PIECE|ML)\b/gi, '') // Remove standalone units
        .trim() // Remove leading/trailing spaces
        .replace(/\s+/g, ' '); // Normalize multiple spaces to a single space
  }

  const sortByDimensionSequence = (data) => {
    console.log("data : ", data)
    const dimens = ['POUCH', 'BOX', 'TIN']; // Define the priority order
  
    return data.sort((a, b) => {
      console.log('a,b :==>  ',a,b)
      const aPriority = dimens.findIndex(keyword => a.name.includes(keyword)) ?? Infinity;
      const bPriority = dimens.findIndex(keyword => b.name.includes(keyword)) ?? Infinity;
  
      // Sort by priority index. If both have the same priority, fallback to default string comparison
      if (aPriority === bPriority) {
        return a.name.localeCompare(b.name);
      }
  
      return aPriority - bPriority;
    });
  }
  
    export { findPincode , groupAndSum , transformToDDMMYYY, buildColDef , preferredCustomerDiscount , 
      getMonthNameByInt , getStoreName , getStorOrWarehouseNameById , addDecimals , formatToIndianCurrency, 
      cleanStringsWithoutDimensions, cleanProductNameWithoutDimensions, sortByDimensionSequence} ;