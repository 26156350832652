import React from "react";
import Alert from "@mui/material/Alert";

const Message = ({ variant, severity, children }) => {
  return <Alert severity={severity}>{children}</Alert>;
};

Message.defaultProps = {
  variant: "danger",
  severity: "error",
};

export default Message;
